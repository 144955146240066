<template>
  <div class="">
    <div class="form-row">
      <sgv-input-file
        class="col-12"
        ref="fileUpload"
        label="ไฟล์ CSV"
        @change="changeToJSON($event)">
      </sgv-input-file>
    </div>

    <button
      class="btn btn-primary"
      type="button"
      @click="reset">
      ยกเลิก
    </button>

    <sgv-csv
      :items="csvData"
      :labels="csvLabels"
      :filename="csvFilename">
      <button
        type="button"
        class="btn btn-info ml-2">
        ตัวอย่าง CSV
      </button>
    </sgv-csv>

    <div class="mt-3" v-if="errorRows.length > 0">
      <span class="text-danger">ไม่สินค้า:</span>
      <sgv-table
        :items="errorRows"
        :headers="headers"
        :options.sync="options"
        headerless>

        <template slot-scope="{item, hidden}">
          <tr class="">
            <td v-if="hidden.code">{{item.code}}</td>
            <td v-if="hidden.name">{{item.name}}</td>
          </tr>
        </template>
      </sgv-table>
    </div>

    <div class="mt-3" v-if="addRows.length > 0">
      <button
        type="button"
        class="btn btn-success btn-sm mb-2"
        @dblclick="addAll">
        เพิ่มทั้งหมด
      </button>
      <sgv-table
        :items="addRows"
        :headers="headers"
        :options.sync="options"
        headerless>

        <template slot-scope="{item, hidden}">
          <tr class="">
            <td v-if="hidden.code">
              <button
                type="button"
                class="btn btn-success btn-sm mr-1"
                @click="addData(item)">
                เพิ่ม
              </button>
              {{item.code}}
            </td>
            <td v-if="hidden.name">{{item.name}}</td>
          </tr>
        </template>
      </sgv-table>
    </div>
  </div>

</template>

<script>
import debounce from 'lodash/debounce'
import retainMixin from '@/mixins/retain-mixin'
import {
  LIST_DOC_CONFIG_IMPORT_INVENTORY,
  CREATE_DOC_CONFIG,
} from './graph'

export default {
  mixins: [retainMixin],
  props: {
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      configType: 'inventoryId',
      rKey: `Doc${this.$form.capitalize(this.docType)}DocConfigImportInventory`,
      headers: [
        {text: 'รหัส', value: 'code'},
        {text: 'ชื่อ', value: 'name'},
      ],
      options: {
        headers: ['code', 'name'],
        column: null,
        search: null,
        toolbar: null,
      },
      csvFilename: 'ตัวอย่างนำเข้าสินค้า',
      csvLabels: {
        code: { title: 'code' }
      },
      csvData: [
        {
          code: 'รหัสสินค้า',
        },
        {
          code: 'A01'
        },
        {
          code: 'B01'
        }
      ],
      errorRows: [],
      addRows: [],
      inputRows: []
    }
  },
  methods: {
    changeToJSON (e) {
      var fileInput = e.target.files[0]
      const options = {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          this.inputRows = results.data.slice(1)
          this.getValidation()
        }
      }
      this.$papa.parse(fileInput, options)
    },
    reset () {
      this.setDefault ()
      this.$refs.fileUpload.reset()
    },
    serializeInput (v) {
      return {
        code: v.code
      }
    },
    getValidation: debounce(function() {
      this.$apollo.query({
        query: LIST_DOC_CONFIG_IMPORT_INVENTORY(this.templateType),
        variables: {
          docType: this.docType,
          input: this.inputRows.map(v => this.serializeInput(v))
        },
        fetchPolicy: 'no-cache',
      })
      .then(result => {
        this.errorRows = result.data.result?.errors || []
        this.addRows = result.data.result?.adds || []
      })
      .catch(err => {
        this.setDefault ()
        this.$toasted.global.error(err)
      })
    }, 300),
    setDefault() {
      this.errorRows = []
      this.addRows = []
    },
    addData (item) {
      const input = {
        type: this.configType,
        inventoryId: item.id
      }

      this.$apollo.mutate({
        mutation: CREATE_DOC_CONFIG(this.templateType),
        variables: {
          docType: this.docType,
          input
        }
      })
      .then(() => {
        this.$toasted.global.success("เพิ่มสำเร็จ")
        this.getValidation()
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    async addAll () {
      const items = this.addRows
      for (let i = 0; i < items.length; i++) {
        await this.addData(items[i])
      }
    }
  }
}
</script>

<style lang="css" scoped>
</style>
